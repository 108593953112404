import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/layout"
import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import "react-multi-carousel/lib/styles.css"
import axios from "axios"
import _noop from "lodash/noop"
import { Link, navigate } from "gatsby"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import getFirebase from "../../firebaseConfig"
import AuthContext from "../contexts/AuthContext"
import { STATUS, VIEWS } from "../constants/common"
import { getMetaData } from "../api/common"
import Signup from "../components/signup"
import ForgotPassword from "./forgotpassword"
import VerifyEmail from "../components/VerifyEmail"

const Login = props => {
  const [Email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const { loginUser, user, signOutUser } = useContext(AuthContext)
  const [metadata, setMetadata] = useState(null)
  const [fetchingMetadata, setFetchingMetadata] = useState(true)
  const [view, setView] = useState(VIEWS.LOGIN)

  useEffect(() => {
    async function fetchMetadata() {
      setFetchingMetadata(true)
      const response = await getMetaData()
      setMetadata(response)
      setFetchingMetadata(false)
    }
    fetchMetadata()
  }, [])

  useEffect(() => {
    if (user) {
      window.location.href = "/"
    }
  }, [user])

  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }

  const loginSubmit = async e => {
    e.preventDefault()

    if (Email !== "" && password !== "") {
      setErrorMessage("")

      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }

      if (!password) {
        setpasswordError("Please Enter Vaild Password")
      } else {
        setpasswordError("")
      }
      if (validateEmail(Email) && password.length >= 2) {
        const { status, msg, data, view } = await loginUser(Email, password)
        if (status === STATUS.FAILED) {
          setEmailError(msg)
          return
        }

        if (view === VIEWS.VERIFY_EMAIL) {
          // setUserDetails(data)
          setView(VIEWS.VERIFY_EMAIL)
          return
        }
        if (user) {
          console.log("user.organizationId", user.organizationId)
          if (window?.history?.length > 0) {
            window?.history?.back() ?? _noop()
          } else {
            window.location.href = "/"
          }
        }
      }
    } else {
      setEmailError("Please enter vaild email address")
      setpasswordError("Please enter vaild password")
    }
  }

  const getLoginView = () => setView(VIEWS.LOGIN)
  const getSignupView = () => setView(VIEWS.SIGNUP)
  const getForgotView = () => setView(VIEWS.FORGOT_PASSWORD)

  const loginComponent = () => {
    return (
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="DesktopOnly">
            <div style={{ paddingTop: "5vh" }}></div>
          </div>
          <div
            style={{
              border: "1px solid #d3d3d3",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <div className="text-center">
              <h3>Login</h3>
            </div>
            <form>
              <div className="form-group">
                <label htmlFor="email">
                  Email Address *:{" "}
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {EmailError}
                  </span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={Email}
                  onChange={e => {
                    setEmail(e.currentTarget.value)
                    setErrorMessage("")
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  Password *:{" "}
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {passwordError}
                  </span>
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={password}
                  onChange={e => {
                    setPassword(e.currentTarget.value)
                    setErrorMessage("")
                  }}
                  required
                />
              </div>
              <div className="text-center">
                <button
                  onClick={loginSubmit}
                  style={{
                    backgroundColor: "#2798B5",
                    alignItems: "center",
                    border: "none",
                    borderRadius: 4,
                    color: "white",
                    padding: "4px 16px",
                  }}
                >
                  Login
                </button>
              </div>
              <br></br>
              <div className="text-center">
                <button
                  onClick={getSignupView}
                  style={{ background: "white", border: 0, color: "#2798B5" }}
                >
                  New User? Signup
                </button>{" "}
              </div>
              <br />
              <div className="text-center">
                <button
                  onClick={getForgotView}
                  style={{ background: "white", border: 0, color: "#2798B5" }}
                >
                  Forgot password?
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-3"></div>
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <br />
          <img
            src="https://assets.gofloaters.com/webp/trust-badge.png"
            className="img-responsive"
          ></img>
        </div>
      </div>
    )
  }

  const signupComponent = () => {
    return <Signup getLoginView={getLoginView} metadata={metadata} />
  }

  const forgotPasswordComponent = () => {
    return <ForgotPassword getSignupView={getSignupView} />
  }

  const verifyEmailComponent = () => {
    return <VerifyEmail email={Email} />
  }

  const getCurrentView = () => {
    if (view === VIEWS.LOGIN) return loginComponent()
    if (view === VIEWS.SIGNUP) return signupComponent()
    if (view === VIEWS.FORGOT_PASSWORD) return forgotPasswordComponent()
    if (view === VIEWS.VERIFY_EMAIL) return verifyEmailComponent()
  }

  return (
    <div>
      <SEOHeader
        title={"Login | GoFloaters"}
        description={"Login | GoFloaters"}
      ></SEOHeader>

      <Layout>
        <div class="container-fluid newspaceDetailContainer noPadding">
          <div className="row alginer">
            <div className="col-md-5 DesktopOnly noPadding">
              <img
                src="https://assets.gofloaters.com/webp/login-page-img.jpg"
                className="img-responsive"
              ></img>
            </div>
            <div className="col-md-7 backgroundElements">
              {fetchingMetadata ? <p> Loading....</p> : getCurrentView()}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Login
